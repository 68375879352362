import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Not everyone buys a Christmas tree. 🎄 Draw a donut chart of people's thoughts.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactviz.holiday/datasets/statistic_id644150_christmas-tree_-purchase-plans-among-us-consumers-2017.xlsx"
      }}>{`Dataset`}</a></p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/aaqfCnE0G6s" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/zlkrm04jjl?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`This donut chart build was short and sweet. D3 has all the ingredients we need,
Chroma's got the colors, d3-svg-legend has nice legend stuff. Oh and we used it
as an excuse to update my `}<a parentName="p" {...{
        "href": "https://d3blackbox.com"
      }}>{`d3blackbox`}</a>{` library so it
actually exports the hooks version.`}</p>
    <p>{`Thought it did, had it in the docs, published version didn't have it. 20 day
old issue report on GitHub. Oops 😅`}</p>
    <p>{`You can see data loading in the Codesandbox above. Here's the fun stuff`}</p>
    <h2 {...{
      "id": "react-and-d3-pie-chart-tutorial-with-react-hooks"
    }}>{`React and D3 pie chart tutorial with React hooks`}</h2>
    <p>{`Pie charts and donut charts are the same. If there's a hole in the middle it's
a donut, otherwise it's a pie. You should always make donuts because donuts are
delicious and easier to read due to intricacies around area size perception.`}</p>
    <p>{`Our code fits in a functional React component`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const TreeDonut = ({ data, x, y, r }) => {};
`}</code></pre>
    <p>{`Takes `}<inlineCode parentName="p">{`data`}</inlineCode>{`, `}<inlineCode parentName="p">{`x,y`}</inlineCode>{` coordinates for positioning, and `}<inlineCode parentName="p">{`r`}</inlineCode>{` for the total radius.`}</p>
    <p>{`We begin with a bunch of D3 objects. Scales, pie generators, things like that.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const pie = d3.pie().value(d => d.percentage);
const arc = d3
  .arc()
  .innerRadius(90)
  .outerRadius(r)
  .padAngle(0.01);
const color = chroma.brewer.set1;
const colorScale = d3
  .scaleOrdinal()
  .domain(data.map(d => d.answer))
  .range(color);
`}</code></pre>
    <p>{`Here's what they do:`}</p>
    <ol>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`d3.pie()`}</inlineCode>{` generator takes data and returns everything you need to
create a pie chart. Start and end angles of each slice and a few extras.`}</li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`d3.arc()`}</inlineCode>{` generator creates path definitions for pie slices. We define
inner and outer radiuses and add some padding.`}</li>
      <li parentName="ol">{`We take the `}<inlineCode parentName="li">{`color`}</inlineCode>{` list from one of Chroma's pre-defined colors.`}</li>
      <li parentName="ol">{`We'll use `}<inlineCode parentName="li">{`colorScale`}</inlineCode>{` for the legend. Maps answers from our dataset to
their colors`}</li>
    </ol>
    <p>{`Next thing we need is some state for the overlay effect. It says which slice is
currently selected.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [selected, setSelected] = useState(null);
`}</code></pre>
    <p>{`Hooks make this way too easy. 😛 We'll use `}<inlineCode parentName="p">{`setSelected`}</inlineCode>{` to set the value and
store it in `}<inlineCode parentName="p">{`selected`}</inlineCode>{`.`}</p>
    <p>{`Then we render it all with a loop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
  <g transform={\`translate(\${x}, \${y})\`}>
    {pie(data).map(d => (
      <path
        d={arc
          .outerRadius(selected === d.index ? r + 10 : r)
          .innerRadius(selected === d.index ? 85 : 90)(d)}
        fill={color[d.index]}
        onMouseOver={() => setSelected(d.index)}
        onMouseOut={() => setSelected(null)}
      />
    ))}
    <Legend x={r} y={r} colorScale={colorScale} />
  </g>
);
`}</code></pre>
    <p>{`A grouping element positions our piechart from the center out.`}</p>
    <p>{`Inside that group, we iterate over the output of our `}<inlineCode parentName="p">{`pie()`}</inlineCode>{` generator and
render a `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` for each entry. Its shape comes from the `}<inlineCode parentName="p">{`arc`}</inlineCode>{` generator.`}</p>
    <p>{`We update inner and outer radius on the fly depending on whether the current
slice is highlighted. This creates the become-bigger-on-mouse-over effect. We
drive it with mouse event callbacks and the `}<inlineCode parentName="p">{`setSelected`}</inlineCode>{` method.`}</p>
    <p><inlineCode parentName="p">{`setSelected`}</inlineCode>{` stores the current selected index in `}<inlineCode parentName="p">{`selected`}</inlineCode>{`. This triggers a
re-render. The selected slice shows as bigger.`}</p>
    <p>{`Perfect 👌`}</p>
    <h2 {...{
      "id": "ps-the-legend-component-with-hooks-is-a-piece-of-cake"
    }}>{`PS: The legend component with hooks is a piece of cake`}</h2>
    <p><inlineCode parentName="p">{`d3-svg-legend`}</inlineCode>{` does it all for us. We use `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` from my d3blackbox to make
it work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Legend = function({ x, y, colorScale }) {
  const ref = useD3(anchor => {
    d3.select(anchor).call(d3legend.legendColor().scale(colorScale));
  });

  return <g transform={\`translate(\${x}, \${y})\`} ref={ref} />;
};
`}</code></pre>
    <p>{`Lets us render any D3 code into an anchor element and wrap it in a React
component. Behind the scenes `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` is a combination of `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` and
`}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`.`}</p>
    <p>{`Enjoy ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      